<template>
  <div>
    <div v-if="NewsListData?.entityById">
      <CommonFieldHeader
        v-if="NewsListData.entityById.fieldHeader"
        :fieldHeaderData="NewsListData.entityById.fieldHeader"
        :isListPage="true"
        :customTitle="headerTitle"
        :showIntroduction="!hasDomainFilter && !hasSearch && pageNumber<2"
        :showBackLink="hasDomainFilter || hasSearch"
        class="mt-12"
      />

      <div v-if="showHighlightedNews" class="lg:border-b lg:border-grisClair lg:pb-16 pt-10 lg:pt-16">
        <div class="container mx-auto">
          <h2 class="text-h2Small md:text-h2 font-normal leading-[35.2px] md:leading-[44px] font-raleway pb-4 lg:pb-6">
            {{ $t("news.highlighted") }}
          </h2>
          <div class="flex flex-wrap gap-4 lg:flex-nowrap">
            <HomeCardsActualityCard
              v-for="newsHighlighted in NewsListData.entityById.computedNewsHighlighted"
              :tag="newsHighlighted.fieldSubtitle"
              :image="newsHighlighted.fieldImage?.entity.uriRawField.list[0].url"
              :title="newsHighlighted.fieldTitle"
              :date="formatDate(newsHighlighted.fieldDate.value)"
              :path="newsHighlighted.pathRawField.list[0].alias"
            />
          </div>
        </div>
      </div>
      <div class="container mx-auto">
        <div id="list" class="grid grid-cols-12 mb-10 lg:mb-20 mt-10 lg:mt-20">
          <div class="col-span-12 block lg:hidden">
            <div class="pb-6 font-lora text-h3Medium leading-[33px] lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("news.searchTitle") }}
            </div>
            <ListSearchForm list="news" />
          </div>

          <div class="col-span-12 mb-6 lg:col-span-4 lg:mb-0 lg:pb-0 lg:pr-20">
            <ListFacets />
          </div>

          <div class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l border-grisClair">
            <h2 class="hidden lg:block pb-6 font-lora text-h3Medium lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("news.searchTitle") }}
            </h2>
            <ListSearchForm list="news" class="hidden lg:flex" />

            <ListItems v-if="searchData.count > 0" list="news" />
            <div v-else>
              <p class="font-raleway text-regularMobile lg:text-regular">{{ $t("news.noResults") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import useConditionsBuilder from "~/composables/conditions/builder";
import useQuery from "~/composables/query";

import getNewsList from "~/composables/service/graphql/client/news-list";

import type { Condition, Facet, OpenSearchNode } from "~/interfaces/list";
import type { SeoTags } from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";
import { watch } from "vue";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
const contextStore = useContextStore();


const config = useRuntimeConfig();
const route = useRoute();
const { t } = useI18n();

const pageNumber = computed(()=> route.query.page ?? 1);

const node = computed(() => routeStore.route?.name?.toString()!);
const baseConditions = ref<Condition[]>([
  { field: "status", values: "1", conjunction: "or" },
  { field: "type", values: "news", conjunction: "or" },
  { field: "field_date", values: [config.public.oldNewsDate], operator: ">=", conjunction:"or"},
]);
const facets = ref<Facet[]>([
  { label: t("news.fieldDate"), type: "Datepicker", field: "field_date" },
  { label: t("news.fieldDomainNews"), type: "Checkbox", field: "field_domain_news" },
]);

const { setBaseConditions, setFacetConditions, setFacets, fetchSearchData, setSortOrder } = useList();
const { buildApiConditions, buildApiConditionsFromQuery } = useConditionsBuilder();
const { handleQueryBaseParameters, reset } = useQuery();
const { langcode, searchData } = storeToRefs(useList());
//reset();
setFacets(facets.value);
setBaseConditions(buildApiConditions(baseConditions.value));

const { data: NewsListData } = (await getNewsList(node.value, langcode.value)) as {
  data: Ref<OpenSearchNode>;
};
contextStore.setContent(NewsListData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();
const backendMetatags = getBackendMetatags(NewsListData.value?.entityById?.fieldMetatag);

const { currentPage } = storeToRefs(useList());

const getCanonicalUrl = (page) => {
  const queries = {...route.query};
  if(page>1){
  queries.page = page;
  }else{
    delete queries.page;
  }
  const queryParamsStr = Object.keys(queries).map(key => `${key}=${queries[key]}`).join("&");
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.path}${queryParamsStr ? '?'+queryParamsStr :""}`;
  return canonicalUrl;
}

const getFormattedMetatags = <SeoTags>(() => {
  const backendMetatags = getBackendMetatags(NewsListData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
    "title",
    (backendMetatags?.title ?? NewsListData.value?.entityById?.fieldHeader?.fieldTitle)
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? NewsListData.value?.entityById?.fieldHeader?.fieldIntroduction2
  );
 
  const canonicalUrl = getCanonicalUrl(route.query.page?? 1);

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

const formattedMetatags = getFormattedMetatags();

const getPaginateMeta = (page) => {
  let suffix = '';
  if(page>1){
    suffix = ` - page ${page}`;
  }
  return {title: `${formattedMetatags.title}${suffix}`, canonical_url: getCanonicalUrl(page) , description: `${formattedMetatags.description}${suffix}`};
}

watch(
  ()=> currentPage.value,
  (value)=> { 
    setMetaTags(getPaginateMeta(value))
  },
  { immediate: true, deep: true }
)
setMetaTags(getPaginateMeta(route.query.page ?? 1))
const hasDomainFilter = computed(() => {
  return route.query["fieldDomainNews[]"] !== undefined;
});

const hasSearch = computed(() => {
  return route.query["q"] !== undefined;
});

const showHighlightedNews = computed(() => {
  return NewsListData.value?.entityById?.computedNewsHighlighted?.length && !hasDomainFilter.value && !hasSearch.value && (pageNumber.value < 2);
});

const headerTitle = computed(() => {
  const domainsIds = route.query["fieldDomainNews[]"];
  const defaultTitle = NewsListData.value?.entityById?.fieldHeader?.fieldTitle;

  if (!domainsIds && !hasSearch.value) {
    return `${defaultTitle}${pageNumber.value >1 ? ` - page ${pageNumber.value}`:''}`;
  }

  const domainFacet = searchData.value?.facets?.find((facet) => facet.field === "field_domain_news");
  
  if (!domainsIds && hasSearch.value) {
    return `${t("news.latestNewsForDomain")} : ${hasSearch.value ? '"'+route.query["q"]+'"' : ""}${pageNumber.value >1 ? ` - page ${pageNumber.value}`:''}`;
  }


  const selectedDomains = domainFacet.values
    ?.filter((value) => domainsIds.includes(value.value))
    .map((value) => `"${value.label.value}"`);

  if (!selectedDomains || selectedDomains.length === 0) {
    return defaultTitle;
  }
  if(hasSearch.value ){
    selectedDomains.push(`"${route.query["q"]}"`);
  }
  return `${t("news.latestNewsForDomain")} : ${selectedDomains.join(", ")}${pageNumber.value >1 ? ` - page ${pageNumber.value}`:''}`;
});

watch(
  () => route.query,
  async (query) => {
      reset();
    handleQueryBaseParameters(query);

    const facetConditions = buildApiConditionsFromQuery(query);
    setFacetConditions(facetConditions);
    setSortOrder({field: "field_date", order: "DESC"});
    await fetchSearchData("newsList");
  },
  { immediate: true, deep: true }
);
useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
~/composables/query
