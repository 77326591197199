<template>
  <div>
    <div v-if="JobOfferListData?.entityById">
      <CommonFieldHeader
        v-if="JobOfferListData.entityById.fieldHeader"
        :fieldHeaderData="JobOfferListData.entityById.fieldHeader"
        :isListPage="true"
        :showIntroduction="pageNumber<2"
        :customTitle="`${JobOfferListData.entityById.fieldHeader.fieldTitle}${pageNumber >1 ? ` - page ${pageNumber}`:''}`"
        class="mt-12"
      />

      <div class="container mx-auto">
        <div id="list" class="grid grid-cols-12 mb-10 mt-10 lg:mt-20 lg:mb-[73px]">
          <div class="col-span-12 block lg:hidden">
            <div class="pb-6 font-lora text-h3Medium leading-[33px] lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("job.searchTitle") }}
            </div>
            <ListSearchForm list="job" />
          </div>
          <div class="col-span-12 mb-6 lg:col-span-4 lg:mb-0 lg:pb-0 lg:pr-20">
            <ListFacets />
          </div>
          <div class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l border-grisClair">
            <h2 class="hidden lg:block pb-6 font-lora text-h3Medium lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("job.searchTitle") }}
            </h2>
            <ListSearchForm list="job" class="hidden lg:flex" />
            <ListItems v-if="searchData.count > 0" list="job" />
            <div v-else>
              <p class="font-raleway text-regularMobile lg:text-regular">
                {{ $t("job.noResults") }}
              </p>
            </div>
            <div class="bg-grisBackground lg:block ml-[-1rem] mr-[-1rem] lg:ml-0 lg:mr-0 mt-10 lg:mt-20">
              <div class="py-10 px-12 flex flex-col items-center">
                <nuxt-icon name="job" filled class="text-[3rem]" />
                <h2
                  class="font-lora text-h3Medium lg:text-h2Small leading-[33px] text-center pt-2 pb-6 font-normal m-0"
                >
                  {{ $t("job.spontaneous") }}
                </h2>
                <div>
                  <UiCustomLink
                    to="/candidature-spontanee"
                    customClass="hover:text-orangeCurie font-raleway text-regularMobile lg:text-regular flex leading-[25px] text-center underline font-bold items-center gap-2.5 cursor-pointer"
                  >
                    {{ $t("job.spontaneousAlt") }}
                  </UiCustomLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import useConditionsBuilder from "~/composables/conditions/builder";
import useQuery from "~/composables/query";
import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();
import getJobOfferList from "~/composables/service/graphql/client/job-offer-list";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import type { Condition, Facet, OpenSearchNode } from "~/interfaces/list";
import type { SeoTags } from "~/interfaces/seo";

const config = useRuntimeConfig();
const route = useRoute();
const { t } = useI18n();
const pageNumber = computed(()=> route.query.page ?? 1);
const node = computed(() => routeStore.route?.name?.toString()!);
const baseConditions = ref<Condition[]>([
  { field: "status", values: "1", conjunction: "or" },
  { field: "type", values: "job_offer", conjunction: "or" },
]);
const facets = ref<Facet[]>([
  { label: t("job.fieldJobSector"), type: "Checkbox", field: "field_job_sector" },
  { label: t("job.fieldJobContract"), type: "Checkbox", field: "field_job_contract" },
  { label: t("job.fieldJobLocation"), type: "Checkbox", field: "field_job_location" },
  { label: t("job.fieldJobEntity"), type: "Checkbox", field: "field_job_entity" },
]);

const { setBaseConditions, setFacetConditions, setFacets, fetchSearchData } = useList();
const { buildApiConditions, buildApiConditionsFromQuery } = useConditionsBuilder();
const { handleQueryBaseParameters, reset } = useQuery();
const { langcode, searchData } = storeToRefs(useList());
const { currentPage } = storeToRefs(useList());
//reset();
setFacets(facets.value);
setBaseConditions(buildApiConditions(baseConditions.value));

const { data: JobOfferListData } = (await getJobOfferList(node.value, langcode.value)) as {
  data: Ref<OpenSearchNode>;
};

contextStore.setContent(JobOfferListData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();
const backendMetatags = getBackendMetatags(JobOfferListData.value?.entityById?.fieldMetatag);



const getCanonicalUrl = (page) => {
  const queries = {...route.query};
  if(page>1){
  queries.page = page;
  }else{
    delete queries.page;
  }
  const queryParamsStr = Object.keys(queries).map(key => `${key}=${queries[key]}`).join("&");
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.path}${queryParamsStr ? '?'+queryParamsStr :""}`;
  return canonicalUrl;
}


const getFormattedMetatags = <SeoTags>(() => {
  const title = formatSeoTag(
    "title",
    backendMetatags?.title ?? JobOfferListData.value?.entityById?.fieldHeader?.fieldTitle
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? JobOfferListData.value?.entityById?.fieldHeader?.fieldIntroduction2,
    backendMetatags?.title ?? JobOfferListData.value?.entityById?.fieldHeader?.fieldTitle
  );
  const canonicalUrl = getCanonicalUrl(route.query.page?? 1);

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});


const formattedMetatags = getFormattedMetatags();

const getPaginateMeta = (page) => {
  let suffix = '';
  if(page>1){
    suffix = ` - page ${page}`;
  }
  return {title: `${formattedMetatags.title}${suffix}`, canonical_url: getCanonicalUrl(page) , description: `${formattedMetatags.description}${suffix}`};
}



watch(
  ()=> currentPage.value,
  (value)=> { 
    setMetaTags(getPaginateMeta(value))
  },
  { immediate: true, deep: true }
)



setMetaTags(getPaginateMeta(currentPage.value))

watch(
  () => route.query,
  async (query) => {
        reset();
    handleQueryBaseParameters(query);

    const facetConditions = buildApiConditionsFromQuery(query);
    setFacetConditions(facetConditions);

    await fetchSearchData("jobOfferList");
  },
  { immediate: true, deep: true }
);

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
