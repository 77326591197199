<template>
  <div class="container mx-auto">
    <UiCustomLink
      v-if="backLink"
      :path="backLink"
      customClass="font-bold flex items-center font-raleway text-regularMobile lg:text-regular mt-14 lg:mt-20 mb-[22px] lg:mb-10 hover:text-orangeCurie"
    >
      <nuxt-icon name="chevronLeft" filled class="mt-1 mr-4 text-xl" />
      {{ $t("seminar.backLink") }}
    </UiCustomLink>
  </div>

  <div v-if="seminar?.entityById" class="pt-2.5 pb-10 border-b border-grisClair lg:pb-16 lg:pt-6">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-12">
        <div class="col-span-1 lg:col-span-7">
          <h1 class="text-h3 lg:text-h1 font-lora leading-[45px] lg:leading-[57px] mb-10 lg:mb-0">
            {{ seminar.entityById.fieldTitle }}
          </h1>
        </div>
        <div class="col-span-1 self-end lg:col-start-9 lg:col-span-4">
          <div class="px-4 py-6 border border-grisClair lg:p-6 rounded">
            <div class="flex flex-col">
              <span
                v-if="date"
                class="pb-4 lg:pb-6 text-orangeCurie font-raleway text-regular lg:text-medium font-bold"
                >{{ date }}</span
              >

              <div class="font-raleway text-regular">
                <p>
                  {{ seminar.entityById.fieldSite }}
                </p>
                <p>
                  {{ seminar.entityById.fieldRoom }}
                </p>
                <p v-if="seminar.entityById.fieldAddress">
                  {{ seminar.entityById.fieldAddress }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mx-auto">
    <div class="grid grid-cols-12 mt-14 lg:mt-20 mb-14 lg:mb-20">
      <div class="col-span-12 lg:col-start-3 lg:col-span-8 lg:pl-[110px] lg:border-l lg:border-grisClair">
        <div class="mb-14 lg:mb-20">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("seminar.description") }}
          </h2>
          <div
            v-html="seminar.entityById.fieldDescription"
            class="font-raleway text-regularMobile lg:text-regular leading-[25px] wysiwyg"
          />
        </div>
        <div v-if="seminar.entityById.fieldOrganizer?.length" class="mb-14 lg:mb-20">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("seminar.organizer") }}
          </h2>
          <div class="space-y-4">
            <div
              v-for="organizer in seminar.entityById.fieldOrganizer"
              class="font-raleway text-regularMobile lg:text-regular leading-[25px]"
            >
              <p class="font-bold flex gap-2">{{ organizer.fieldTitle }} {{ organizer.fieldFirstName }} {{ organizer.fieldLastName }}</p>
              <p>{{ organizer.fieldInstitute }}</p>
            </div>
          </div>
        </div>
        <div v-if="seminar.entityById.fieldSpeaker?.length" class="mb-14 lg:mb-20">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("seminar.speakers") }}
          </h2>
          <div class="space-y-4">
            <div
              v-for="speaker in seminar.entityById.fieldSpeaker"
              class="font-raleway text-regularMobile lg:text-regular leading-[25px]"
            >
              <p class="font-bold">{{ speaker.fieldFirstName }} {{ speaker.fieldLastName }}</p>
              <p>{{ speaker.fieldInstitute }}</p>
            </div>
          </div>
        </div>
        <div v-if="seminar.entityById.fieldInvitingPersons?.length" class="mb-14 lg:mb-20">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("seminar.invitingPersons") }}
          </h2>
          <div class="space-y-4">
            <div
              v-for="guest in seminar.entityById.fieldInvitingPersons"
              class="font-raleway text-regularMobile lg:text-regular leading-[25px]"
            >
              <p class="font-bold">{{ guest.fieldFirstName }} {{ guest.fieldLastName }}</p>
              <p>{{ guest.fieldInstitute }}</p>
            </div>
          </div>
        </div>
        <div v-if="seminar.entityById.fieldContact?.length">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("seminar.contact") }}
          </h2>
          <div class="space-y-4">
            <div
              v-for="contact in seminar.entityById.fieldContact"
              class="font-raleway text-regularMobile lg:text-regular leading-[25px]"
            >
              <p class="font-bold">{{ contact.fieldTitle }} {{ contact.fieldFirstName }} {{ contact.fieldLastName }}</p>
              <a v-if="contact.fieldEmail" :href="`mailto:${contact.fieldEmail}`" class="flex hover:text-orangeCurie">
                <nuxt-icon name="mail" filled class="cursor-pointer text-h3Small inline mr-2 mt-0.5" />
                {{ contact.fieldEmail }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import getSeminar from "~/composables/service/graphql/client/seminar";
import { useBackLink } from "~/composables/backlink";
import { formatWrittenDate, formatWrittenTime } from "~/utils/dateUtils";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();

import type { Seminar } from "~/interfaces/seminar";
import type { SeoTags } from "~/interfaces/seo";

const config = useRuntimeConfig();
const route = useRoute();
const langcode = config.public.langcode;

const { data: seminar } = (await getSeminar(routeStore.route?.name?.toString()!, langcode)) as {
  data: Ref<Seminar>;
};
contextStore.setContent(seminar.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(seminar.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? seminar.value?.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? seminar.value?.entityById?.fieldDescription,
    backendMetatags?.title ?? seminar.value?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

const { getBackLink } = useBackLink();
const backLink = getBackLink();

const date = computed(() => {
  const { fieldDateBegin, fieldDateEnd } = seminar.value.entityById;
  const beginDate = fieldDateBegin.value;
  const endDate = fieldDateEnd?.value;

  if (endDate) {
    const beginDateFormatted = formatWrittenDate(beginDate);
    const endDateFormatted = formatWrittenDate(endDate);
    const beginDateOnly = beginDateFormatted.split(" ")[0];
    const endDateOnly = endDateFormatted.split(" ")[0];

    if (endDateOnly !== beginDateOnly) {
      return `${beginDateFormatted} / ${endDateFormatted}`;
    } else {
      return `${beginDateFormatted} - ${formatWrittenTime(endDate)}`;
    }
  }

  return formatWrittenDate(beginDate);
});

useCDNHeaders((helper) => {
  helper
    .private();
});

useJsonld(() => ({
  "@context": "https://schema.org",
  "@type": "Event",
  name: seminar.value?.entityById?.fieldTitle,
  description: seminar.value?.entityById?.fieldDescription,
  startDate: seminar.value?.entityById?.fieldDateBegin.value,
  endDate: seminar.value?.entityById?.fieldDateEnd.value,
  location: {
    "@type": "Place",
    name: seminar.value?.entityById?.fieldSite,
    address: {
      "@type": "PostalAddress",
      streetAddress: seminar.value?.entityById?.fieldAddress,
    },
  }
}));
</script>
