<template>
  <NewsFieldHeader :newsHeader="headerData" />
  <CommonFieldAnchor :fieldAnchorData="NewsItems.entityById.fieldAnchor" />
  <div class="container mx-auto">
    <div class="grid grid-cols-1 lg:grid-cols-12">
      <section
        class="lg:col-span-8 lg:col-start-3 lg:pl-28 lg:border-l lg:border-grisClair mb-[60px] mt-10 lg:mt-20 lg:mb-36"
      >
        <div v-if="NewsItems.entityById.fieldIntroductionNews" class="font-normal font-lora text-medium">
          {{ NewsItems.entityById.fieldIntroductionNews }}
        </div>
        <component
          v-if="NewsItems.entityById?.fieldParagraphs?.length > 0"
          v-for="paragraph in NewsItems.entityById.fieldParagraphs"
          :key="paragraph.id"
          :is="getComponentByType(paragraph.__typename)"
          :paragraphData="paragraph"
          :id="paragraph.fieldAnchor"
          class="my-10"
        />
        <div v-else>
          <div class="wysiwyg" v-html="NewsItems.entityById.fieldHtml" />
        </div>
      </section>
    </div>
  </div>
  <section class="container mx-auto">
    <div v-if="NewsItems.entityById?.computedNewsSameDomain?.length > 0" class="flex justify-between">
      <h2
        v-if="NewsItems.entityById.fieldDomainNews[0].fieldTitle"
        class="font-raleway font-normal text-[2rem] lg:text-h2 m-0"
      >
        {{ NewsItems.entityById.fieldDomainNews[0].fieldTitle }}
      </h2>
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        class="hidden items-center self-end text-lg font-bold underline lg:flex font-raleway hover:text-orangeCurie"
      >
        {{ $t("actuality.discoverAll") }}
        <nuxt-icon name="arrowRight" filled class="ml-2" />
      </UiCustomLink>
    </div>
    <div class="grid grid-cols-1 grid-rows-1 pt-4 lg:grid-rows-2 lg:grid-cols-12 lg:pt-6 mb-14 lg:mb-20">
      <div
        v-for="(sameNews, index) in NewsItems.entityById.computedNewsSameDomain"
        :class="{
          'col-span-1 row-span-1 flex flex-col lg:flex-row lg:col-span-8 lg:row-span-2 gap-0 lg:gap-8 lg:mr-10 group relative':
            index === 0,
          'col-span-4 row-span-1  py-4 lg:pb-3 lg:pt-0 relative': index === 1,
          'row-span-2 col-span-4  py-4 lg:py-3 relative': index === 2,
        }"
        :key="sameNews.id"
      >
        <div v-if="index === 0" class="overflow-hidden lg:min-w-[430px] mb-4 rounded underline-animation-border">
          <UiCustomImage
            :src="sameNews.fieldImage.entity.uriRawField.list[0].url"
            :alt="sameNews.fieldImage.alt"
            presets="field_header"
            customClass="w-full rounded object-cover col-span-4 group-hover:scale-[1.1] transition ease delay-600"
          />
        </div>
        <div
          :class="{
            'pb-4 lg:pr-10 group-hover:border-b-orangeCurie hover:border-b-1': index === 0,
          }"
        >
          <p class="pb-2.5 font-lora text-regular lg:pt-0 text-orangeCurie">
            {{ sameNews.fieldSubtitle }}
          </p>
          <p class="font-lora text-medium leading-[25px] lg:text-h3Small pb-4 underline-animation-border">
            <UiCustomLink :path="sameNews.pathRawField?.list[0]?.alias">
              {{ sameNews.fieldTitle }}
            </UiCustomLink>
          </p>
          <p
            class="font-raleway text-regularMobile lg:text-regular leading-[25px] pb-4"
            v-if="sameNews.fieldIntroductionNews && index === 0"
          >
            {{ truncate(sameNews.fieldIntroductionNews, 350) }}
          </p>
          <p class="font-raleway text-small">
            {{ formatDate(sameNews.fieldDate.value) }}
          </p>
        </div>
      </div>
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        class="flex mt-4 lg:hidden items-center self-end text-regularMobile lg:text-lg font-bold underline font-raleway hover:text-orangeCurie"
      >
        {{ $t("actuality.discoverAll") }}
        <nuxt-icon name="arrowRight" filled class="ml-2" />
      </UiCustomLink>
    </div>
  </section>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getNews from "~/composables/service/graphql/client/news/index";
import { getComponentByType } from "~/utils/componentMapper";
import { formatDate } from "~/utils/dateUtils";
import { truncate } from "~/utils/stringUtils";
import { useBackLink } from "~/composables/backlink";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import type { SeoTags } from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";
import { getDefaultPageTags } from "~/utils/cacheTags";

const contextStore = useContextStore();


const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { data: NewsItems } = await getNews(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(NewsItems?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(NewsItems?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? NewsItems?.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? NewsItems?.entityById?.fieldIntroductionNews,
    backendMetatags?.title ?? NewsItems?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

const { getBackLink } = useBackLink();
const backLink = getBackLink();

const headerData = computed(() => ({
  fieldTitle: NewsItems.entityById.fieldTitle,
  fieldSubtitle: NewsItems.entityById.fieldSubtitle,
  fieldImage: NewsItems.entityById.fieldImage,
  fieldDate: NewsItems.entityById.fieldDate,
}));

const newsTags = NewsItems.entityById?.computedNewsSameDomain.map((news)=>{return `page:${news.id}`});
const tags = [`page:${NewsItems.entityById.id}`, ...getDefaultPageTags(), ...newsTags];
useCDNHeaders((helper) => {
  helper
    .addTags(tags)
    .public()
    .setNumeric('maxAge', 86400)
    .setNumeric('staleIfError', 43200)
})

useJsonld(() => ({
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  headline: NewsItems.entityById?.fieldTitle,
  image: [
    NewsItems.entityById?.fieldImage?.entity?.uriRawField?.list[0].url
  ],
  datePublished: NewsItems.entityById.fieldDate.value + "T00:00:00"
}));
</script>
