<template>
  <div id="anchor" class="pb-4 pt-6 px-4 lg:p-10 border border-grisClair rounded h-[100%] scroll-mt-20">
    <Form v-if="showSuccess == false" :validation-schema="schema" v-slot="{ errors }" @submit="onSubmitForm">
         <h2  class="m-0 mb-6 font-lora font-normal text-h3Medium lg:text-h2Small leading-[33px] lg:leading-[40px]">{{ props.title }}</h2>
            <div class="flex flex-col">
              <label class="pb-2 font-bold font-raleway text-regular"
              >{{ $t("contact.subject_label") }} <span class="text-red">*</span></label
              >
              <UiDropdownContact @click="goToNextStep" :listItems="subjectElements" @selectSubject="selectSubject"/>
              <UiButton
                  v-if="showFormStep == SUBJECT_STEP"
                  to="#"
                  :buttonLabel="$t('Send')"
                  size="medium"
                  :withIcon="false"
                  :class="formData.object ? 'w-full text-center lg:w-fit mt-4 lg:mt-6  md:mb-0' : 'w-full text-center lg:w-fit mt-4 lg:mt-4  md:mb-0 opacity-25 pointer-events-none'"
              />
            </div>
            <div v-if="showFormStep == FORM_STEP" class="flex flex-col gap-4 lg:gap-6 mt-6">
              <div class="flex flex-col">
                <label class="pb-2.5 font-bold font-raleway text-regular"
                >{{ $t("Name") }} <span class="text-red">*</span></label
                >
                <Field
                    class="p-4 rounded border border-grisClair font-regular font-raleway"
                    type="text"
                    name="last_name"
                    :label="$t('Name')"
                    v-model="formData.last_name"
                />
                <span v-if="errors.last_name" class="pt-1 font-raleway text-small text-red"
                >{{ errors.last_name }}</span>
              </div>
              <div class="flex flex-col">
                <label class="pb-2 font-bold font-raleway text-regular"
                >{{ $t("Firstname") }} <span class="text-red">*</span></label
                >
                <Field
                    class="p-4 rounded border border-grisClair font-regular font-raleway"
                    type="text"
                    name="first_name"
                    :label="$t('Firstname')"
                    v-model="formData.first_name"
                />
                <span class="pt-1 font-raleway text-small text-red"
                >{{ errors.first_name }}</span>
              </div>
              <div class="flex flex-col">
                <label class="pb-2 font-bold font-raleway text-regular"
                >{{ $t("Email") }} <span class="text-red">*</span></label
                >
                <Field
                    class="p-4 rounded border font-regular font-raleway"
                    type="email"
                    name="email"
                    :label="$t('Email')"
                    v-model="formData.email"
                />
                <span class="pt-1 font-raleway text-small text-red"
                >{{ errors.email }}</span>
              </div>
              <div class="flex flex-col">
                <label class="pb-2 font-bold font-raleway text-regular"
                >{{ $t("Message") }} <span class="text-red">*</span></label
                >
                <Field
                    as="textarea"
                    rows="10"
                    class="p-4 rounded border border-grisClair font-regular font-raleway"
                    type="text"
                    name="message"
                    :label="$t('Message')"
                    v-model="formData.message"
                />
                <span class="pt-1 font-raleway text-small text-red"
                >{{ errors.message }}</span>
              </div>
              <div class="flex flex-col">
                <label
                    for="doc"
                    class="pb-2 font-bold font-raleway text-regular"
                >{{ $t("File (optional)") }} </label
                >
                <div
                    class="flex flex-col gap-2.5 lg:flex-row lg:items-center"
                >
                  <label
                      for="doc"
                      class="px-6 py-4 rounded font-bold border w-fit font-raleway text-regular border-grisClair"
                  >
                    {{ $t("Add a file") }}
                  </label>
                  <span id="file-name" class="file-box"
                  >{{ uploadData.document ? uploadData.document.name : $t("No choosen File") }}</span
                  >
                </div>
                <input
                    
                    class="hidden rounded p-4 border border-grisClair font-regular font-raleway"
                    id="doc"
                    type="file"
                    name="document"
                    @change="handleFileUpload($event)"
                />
                <div class="pt-1 font-raleway text-small text-red"
                v-html="validationErrors.document?.join('<br />')"
                ></div>
              </div>
              <input type="submit"
                    :value="$t('Send')"
                     class="w-full bg-orangeCurie hover:bg-orangeHover text-white text-center lg:w-fit md:mb-0 mt-4 cursor-pointer inline-block text-lg leading-none font-bold font-raleway rounded py-4 px-6"/>
            </div>
          </Form>
          
          <!-- Formulaire Success message -->
          <div v-if="showSuccess">
            <div class="m-0 mb-6 font-lora text-h3Medium lg:text-h2Small leading-[33px] lg:leading-[40px]">{{ $t("contact.sended") }}
            </div>
            <div class="font-raleway text-regularMobile lg:text-[1rem] leading-[25px] pb-6">
              <p>{{ $t("contact.success_intro") }}</p><br/>
                <p>{{ $t("contact.success_body") }}</p><br/>
                <p>{{ $t("contact.success_end") }}</p>
            </div>
            <a href="#" @click="resend"
               class="flex items-center font-raleway font-bold text-grisFont underline hover:text-orangeCurie text-regularMobile lg:text-regular">
              {{ $t("contact.renew_message") }}
              <nuxt-icon name="arrowRight" filled class="ml-2" />
            </a>
          </div>
      </div>
</template>
<script setup lang="ts">
import {ref} from "vue";
import { objectGroupBy } from "~/utils/objectGroupBy";
import submitForm from "~/composables/service/graphql/client/form/submit";
import upload from "~/composables/service/graphql/client/form/upload";
import { Form, Field, defineRule, configure } from 'vee-validate';
import { required, email} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import { useReCaptcha } from 'vue-recaptcha-v3';

const gtm = useGtm();

const recaptchaInstance = useReCaptcha();
const recaptcha = async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded();
    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha("recaptcha_v3");
    return token;
};


defineRule('required', required);
defineRule('email', email);

configure({
  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'This field is required',
      email: 'This email is not a valid email address',
    },
  }),
});

configure({
  // Generates an Frensh message locale generator
  generateMessage: localize('fr', {
    messages: {
      required: 'Ce champs est requis',
      email: 'Cette adresse e-mail n\'est pas reconnue',
    },
  }),
});

const SUBJECT_STEP = 1;
const FORM_STEP = 2;
const FORM_ID="contact"; 
const nuxtApp = useNuxtApp();

const props = defineProps({
  subjects: Array,
  title: String!
});

const validationErrors = ref({});
const schema = {
  last_name: 'required',
  first_name: 'required',
  email: 'required|email',
  message: 'required',
};


interface ContactFormData{
  object: string;
  last_name: string;
  first_name: string;
  email: string;
  message: string;
  document: string;
  captcha_token: string;
  action: string;
}

interface UploadFormData{
  document: File
}

const uploadData = ref({
    document: null,
  }
);

const formData = ref({
  object: null,
  last_name: null,
  first_name: null,
  email: null,
  message: null,
  document: "",
  captcha_token: null,
  action: null,
});



const selectSubject = (value) => {
  formData.value.object = value;
};


const subjectElements = objectGroupBy(props.subjects , ({ fieldSubjectCategory }) => fieldSubjectCategory);
const showFormStep = ref(1);
const showSuccess =ref(false);
const file = ref(null);


const handleFileUpload = async() => {

    uploadData.value.document = event.target.files[0] ;
}

const goToNextStep = (e)=>{
  e.preventDefault();
  if(formData.value.object){
    showFormStep.value = FORM_STEP;
  }
  return false;
}

const onSubmitForm = async(values) => {
        if(uploadData.value){
        //reset validation errors
        validationErrors.value= {};

        const { data: uploadResult, uploadError } = await upload(
          nuxtApp.$apollo.clients.default,
          FORM_ID,
          uploadData.value
        );

        if(uploadResult.webformUploadFileUpload.validationErrors.length>0){
          for(const index in  uploadResult.webformUploadFileUpload.validationErrors){
            let validationError = uploadResult.webformUploadFileUpload.validationErrors[index];
            validationErrors.value[validationError.element] = validationError.messages;
          }
          return;
        }
        
        const result = {};
        for (const [key, value] of Object.entries(uploadData.value)) {
          result[key] = false;
        }
       
        for(const key in uploadResult.webformUploadFileUpload.results){
          formData.value[uploadResult.webformUploadFileUpload.results[key].element] = uploadResult.webformUploadFileUpload.results[key].fid;
        }
      }
      formData.value.captcha_token = await recaptcha();
      formData.value.action = "recaptcha_v3";
      const { data: formResult, error } = await submitForm(
        nuxtApp.$apollo.clients.default,
        FORM_ID,
        formData.value
      );
      if(formResult.submitWebform.submission.hasOwnProperty("id")){
        let anchor = document.querySelector("#anchor");
        anchor?.scrollIntoView();
        showSuccess.value=true;

        triggerEvent();
      }
  }

const triggerEvent = () => {
  gtm.trackEvent({
    event: 'contact_form',
    contact_subject: formData.value.object,
  })
}

const resend = (event) => {
  event.preventDefault();
  formData.value = 
  {
    object: null,
    last_name: null,
    first_name: null,
    email: null,
    message: null,
    document: "",
    captcha_token: null,
    action: null,
  }
  showFormStep.value = SUBJECT_STEP
  showSuccess.value=false;
  uploadData.value = { document: null};
}

</script>

