import { NewsQuery } from "../../query/content/News";

export default async function getNews(id: string, langcode: string) {
  const TAGS : string[] = [];
  const key = `page--${id}`;
  const nuxtApp = useNuxtApp();
  const { client } = useApolloClient();
  const { data: news } = await useAsyncData(key, async () => {
    try {
      const { value, addToCache } = await useDataCache(key)
      if (value) {
        return value
      }
      const { data: response } = await client.query({query: NewsQuery, variables:{ id, langcode }});
      TAGS.push(`page:${id}`);
      if(response.entityById?.computedNewsSameDomain){
        for (const element of response.entityById?.computedNewsSameDomain) {
          TAGS.push(`page:${element.id}`);
        }
      }
      await addToCache(response, TAGS);
      return response;
    } catch (err) {  
      console.error(err);  
    }
  })
  return {data: news.value };
}
