import { PublicationQuery } from "../../query/content/Publication";

export default async function getPublication(id: string, langcode: string) {
  const TAGS : string[] = [];
  const key = `page--${id}`;
  const nuxtApp = useNuxtApp();
  const { client } = useApolloClient();
  const { data: news } = await useAsyncData(key, async () => {
      const { value, addToCache } = await useDataCache(key)
      if (value) {
        return value
      }
      const { data: response } = await client.query({query: PublicationQuery, variables:{ id, langcode }});
      TAGS.push(`page:${id}`);
      if(response.entityById?.computedPublicationTeam){
        for (const element of response.entityById?.computedPublicationTeam) {
          TAGS.push(`page:${element.id}`);
        }
      }
      if(response.entityById?.fieldAuthorCurie){
        for (const element of response.entityById?.fieldAuthorCurie.filter((element) => element != null)) {
          TAGS.push(`page:${element.id}`);
        }
      }
      await addToCache(response, TAGS);
      return response;
  })
  return {data: news.value };
}
