<template>
  <div v-if="CardCancerItems" class="mt-10">
    <CommonFieldHeader
      :fieldHeaderData="CardCancerItems.entityById.fieldHubCancer?.fieldHeaderRawField?.list[0].entity"
      :isHubCancerPage="true"
    />
    <section class="container grid grid-cols-1 mx-auto lg:grid-cols-4 mt-14 lg:mt-20">
      <div class="w-full col-span-1 lg:pr-20 lg:w-auto">
        <CancerFieldMenu :menuData="CardCancerItems.entityById.fieldHubCancer.fieldMenu" class="hidden lg:block" />
        <div>
          <CancerHubCancerDropdown
            :listItems="CardCancerItems.entityById.fieldHubCancer.fieldMenu"
            class="block w-full lg:hidden mb-10"
          />
        </div>
      </div>
      <div
        id="content"
        v-if="CardCancerItems.entityById.fieldParagraphs"
        class="lg:col-span-3 lg:pl-20 lg:border-l lg:border-grisClair"
      >
        <h1
          v-if="CardCancerItems.entityById.fieldTitle"
          class="font-normal font-lora leading-[35px] lg:leading-[40px] text-h3Medium lg:text-h2Small m-0"
        >
          {{ CardCancerItems.entityById.fieldTitle }}
        </h1>
        <component
          v-for="paragraph in CardCancerItems.entityById.fieldParagraphs"
          :key="paragraph.id"
          :is="getComponentByType(paragraph.__typename)"
          :paragraphData="paragraph"
          class="my-10"
        />
        <CancerFieldBottomNavigation
          :menuData="CardCancerItems.entityById.fieldHubCancer.fieldMenu"
          class="hidden lg:block"
        />
        <CancerMobileBottomNavigation
          :links="CardCancerItems.entityById.fieldHubCancer.fieldMenu"
          class="block w-full lg:hidden"
        />
      </div>
    </section>
    <div v-if="CardCancerItems.entityById.fieldHubCancer.fieldFooter" class="container mx-auto">
      <component
        v-for="paragraph in CardCancerItems.entityById.fieldHubCancer.fieldFooter"
        :key="paragraph.fieldParagraph.id"
        :is="getComponentByType(paragraph.fieldParagraph.__typename)"
        :paragraphData="paragraph.fieldParagraph"
        class="my-10"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getCardCancer from "~/composables/service/graphql/client/hub-cancer/index";
import { getComponentByType } from "~/utils/componentMapper";

import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();

import type { SeoTags } from "~/interfaces/seo";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();

const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { data: CardCancerItems } = await getCardCancer(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(CardCancerItems.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(CardCancerItems.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? CardCancerItems.value?.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? CardCancerItems.value?.entityById?.fieldParagraphs[0]?.fieldBody,
    backendMetatags?.title ?? CardCancerItems.value?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

useCDNHeaders((helper) => {
  helper
    .private();
})

</script>
