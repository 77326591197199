import { gql } from "graphql-tag";

export const EditoFlexibleQuery = gql`
  query MyQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      id
      ... on NodeCms {
        langcode
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldHeader {
          ...headerParagraph
        }
        fieldAnchor {
          title
          uri {
            path
          }
        }
        fieldSection {
          ... on ParagraphSection {
            id
            fieldTypeSection
            fieldParagraphs {
              ...wysiwygParagraph
              ...visualLegendParagraph
              ...videoLegendParagraph
              ...titleParagraph
              ...taglineParagraph
              ...quotationParagraph
              ...keyFigureParagraph
              ...collapsibleParagraph
              ...carouselPersonsParagraph
              ...bannerImageParagraph
              ...columnParagraph
              ...greyBackgroundParagraph
              ...pushParagraph
              ...sourceParagraph
              ...textImageParagraph
              ...donationParagraph
              ...testimonyParagraph
              ...logoParagraph
              ...thumbnailParagraph
              ...linkParagraph
              ...newsParagraph
              ...carouselTestimonyParagraph
            }
          }
        }
        fieldFooter {
          fieldParagraph {
            ...bannerImageParagraph
            ...donationParagraph
            ...pushParagraph
            ...testimonyParagraph
            ...textImageParagraph
          }
        }
      }
    }
  }

  fragment headerParagraph on ParagraphHeader {
    id
    type: entityBundle
    fieldTitle
    fieldIntroduction2
    fieldImage {
      id: targetId
      alt
      entity {
        id
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldTitlePush
    fieldTextPush: fieldBody
    fieldLinkPush: fieldLink {
      title
      uri {
        path
      }
    }
  }

  fragment wysiwygParagraph on ParagraphWysiwyg {
    id
    type: entityBundle
    fieldAnchor
    fieldBody
  }

  fragment visualLegendParagraph on ParagraphVisualLegend {
    id
    type: entityBundle
    fieldAnchor
    fieldImage {
      id: targetId
      alt
      entity {
        id
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldLegend
  }

  fragment videoLegendParagraph on ParagraphVideoLegend {
    id
    type: entityBundle
    fieldAnchor
    fieldVideo
    fieldLegend
  }

  fragment titleParagraph on ParagraphTitle {
    id
    type: entityBundle
    fieldAnchor
    fieldTitle
    fieldTitleLevel
  }

  fragment taglineParagraph on ParagraphTagline {
    id
    type: entityBundle
    fieldAnchor
    fieldTagline
  }

  fragment quotationParagraph on ParagraphQuotation {
    id
    type: entityBundle
    fieldAnchor
    fieldQuotation
    fieldAuthor
    fieldJob
  }

  fragment keyFigureParagraph on ParagraphKeyFigure {
    id
    type: entityBundle
    fieldTitle
    fieldSubtitle
    fieldIntroduction
    fieldAnchor
    fieldKeyFigureItem {
      ... on ParagraphKeyFigureItem {
        id
        type: entityBundle
        fieldNumber
        fieldExponent
        fieldText
      }
    }
  }

  fragment collapsibleParagraph on ParagraphCollapsible {
    id
    type: entityBundle
    fieldAnchor
    fieldCollapsibleItem {
      ... on ParagraphCollapsibleItem {
        id
        type: entityBundle
        fieldQuestion
        fieldAnswer
      }
    }
  }

  fragment carouselPersonsParagraph on ParagraphCarouselPersons {
    id
    type: entityBundle
    fieldAnchor
    fieldPersons {
      fieldFirstName
      fieldLastName
      fieldPersonTitle {
        name
      }
      fieldImage {
        id: targetId
        alt
        title
        entity {
          id
          uriRawField {
            list {
              url
            }
          }
        }
      }
      pathRawField {
        list {
          alias
        }
      }
    }
  }

  fragment bannerImageParagraph on ParagraphBannerImage {
    id
    type: entityBundle
    fieldAnchor
    fieldBannerImageItem {
      fieldTitle
      fieldText
      fieldLink {
        title
        uri {
          path
        }
      }
      fieldImage {
        id: targetId
        alt
        entity {
          id
          uriRawField {
            list {
              url
            }
          }
        }
      }
      fieldImageMobile {
        id: targetId
        alt
        entity {
          id
          uriRawField {
            list {
              url
            }
          }
        }
      }
    }
  }

  fragment greyBackgroundParagraph on ParagraphGreyBackground {
    id
    type: entityBundle
    fieldAnchor
    fieldBody
  }

  fragment columnParagraph on ParagraphColumns {
    id
    type: entityBundle
    fieldAnchor
    fieldTitle
    fieldColomnsItem {
      id
      fieldTitle
      fieldBody
      fieldImage {
        id: targetId
        alt
        entity {
          id
          uriRawField {
            list {
              url
            }
          }
        }
      }
    }
  }

  fragment pushParagraph on ParagraphPush {
    id
    type: entityBundle
    fieldAnchor
    fieldPushItem {
      fieldImage {
        id: targetId
        alt
        entity {
          id
          uriRawField {
            list {
              url
            }
          }
        }
      }
      fieldSubtitle
      fieldTitle
      fieldBody
    }
  }

  fragment sourceParagraph on ParagraphSource {
    id
    type: entityBundle
    fieldAnchor
    fieldBody
  }

  fragment textImageParagraph on ParagraphTextImage {
    id
    type: entityBundle
    fieldAnchor
    fieldImage {
      id: targetId
      alt
      entity {
        id
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldPositionImage
    fieldSubtitle
    fieldTitle
    fieldBody
  }

  fragment linkParagraph on ParagraphLink {
    id
    type: entityBundle
    fieldAnchor
    fieldTitle
    fieldBody
    fieldLink {
      title
      uri {
        path
      }
    }
    fieldColumnNumber
    fieldLinkItem {
      id
      fieldImage {
        id: targetId
        alt
        entity {
          id
          uriRawField {
            list {
              url
            }
          }
        }
      }
      fieldLink {
        title
        uri {
          path
        }
      }
    }
  }

  fragment thumbnailParagraph on ParagraphThumbnail {
    id
    type: entityBundle
    fieldAnchor
    fieldTitle
    fieldSubtitle
    fieldBody
    fieldImages {
      id: targetId
      alt
      title
      entity {
        id
        uriRawField {
          list {
            url
          }
        }
      }
    }
  }

  fragment logoParagraph on ParagraphLogo {
    id
    type: entityBundle
    fieldAnchor
    fieldTitle
    fieldSubtitle
    fieldBody
    fieldImages {
      id: targetId
      alt
      title
      entity {
        id
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldLink {
      title
      uri {
        path
      }
    }
  }

  fragment testimonyParagraph on ParagraphTestimony {
    id
    type: entityBundle
    fieldAnchor
    fieldImage {
      id: targetId
      alt
      entity {
        id
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldSubtitle
    fieldText
    fieldFirstName
    fieldAge
    fieldLink {
      title
      uri {
        path
      }
    }
  }

  fragment donationParagraph on ParagraphDonation {
    id
    type: entityBundle
    fieldAnchor
    fieldTitle
  }

  fragment carouselTestimonyParagraph on ParagraphCarrouselTestimony {
    ... on ParagraphCarrouselTestimony {
      id
      fieldAnchor
      fieldTitle
      fieldTestimony {
        pathRawField {
          list {
            alias
          }
        }
        fieldImage {
          id: targetId
          alt
          entity {
            id
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldFirstName
        fieldCharacteristic
        fieldVerbatim
      }
    }
  }

  fragment newsParagraph on ParagraphNews {
    id
    type: entityBundle
    fieldAnchor
    fieldDomainNews {
      fieldTitle
    }
    computedParagraphNewsDomain {
      ... on NodeNews {
        id
        pathRawField {
          list {
            alias
          }
        }
        fieldTitle
        fieldDate {
          value
        }
        fieldIntroductionNews
        fieldImage {
          id: targetId
          alt
          entity {
            id
            uriRawField {
              list {
                url
              }
            }
          }
        }
      }
    }
  }
`;
