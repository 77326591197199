<template>
  <div>
    <div v-if="SeminarListData?.entityById">
      <CommonFieldHeader
        v-if="SeminarListData.entityById.fieldHeader"
        :fieldHeaderData="SeminarListData.entityById.fieldHeader"
        :isListPage="true"
        class="mt-12"
        :showIntroduction="pageNumber<2"
        :customTitle="`${SeminarListData.entityById.fieldHeader.fieldTitle}${pageNumber >1 ? ` - page ${pageNumber}`:''}`"
      />

      <div class="container mx-auto">
        <div id="list" class="grid grid-cols-12 my-14 mt-10 lg:my-20">
          <div class="col-span-12 block lg:hidden">
            <div class="pb-6 font-lora text-h3Medium leading-[33px] lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("seminar.search") }}
            </div>
            <ListSearchForm list="seminar" />
          </div>

          <div class="col-span-12 mb-6 lg:col-span-4 lg:mb-0 lg:pb-0 lg:pr-20">
            <ListFacets />
          </div>

          <div class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l lg:border-grisClair">
            <h2 class="hidden lg:block pb-6 font-lora text-h3Medium lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("seminar.search") }}
            </h2>
            <ListSearchForm list="seminar" class="hidden lg:flex" />
            <ListItems v-if="searchData.count > 0" list="seminar" />
            <div v-else>
              <p class="font-raleway text-regularMobile lg:text-regular">{{ $t("seminar.noResults") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import useConditionsBuilder from "~/composables/conditions/builder";
import useQuery from "~/composables/query";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import getSeminarList from "~/composables/service/graphql/client/seminar-list";

import type { Condition, Facet, OpenSearchNode } from "~/interfaces/list";
import type { SeoTags } from "~/interfaces/seo";

import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();


const config = useRuntimeConfig();
const route = useRoute();
const { t } = useI18n();

const pageNumber = computed(()=> route.query.page ?? 1);
const node = computed(() => routeStore.route?.name?.toString()!);
const baseConditions = ref<Condition[]>([
  { field: "status", values: "1", conjunction: "or" },
  { field: "type", values: "seminar", conjunction: "or" },
]);
const facets = ref<Facet[]>([
  { label: t("seminar.fieldDateBegin"), type: "Datepicker", field: "field_date_begin" },
  { label: t("seminar.fieldType"), type: "SeminarType", field: "field_date_begin" },
]);

const { setBaseConditions, setFacetConditions, setFacets, fetchSearchData, setSortOrder } = useList();
const { buildApiConditions, buildApiConditionsFromQuery } = useConditionsBuilder();
const { handleQueryBaseParameters, reset } = useQuery();
//reset();
const { langcode, searchData } = storeToRefs(useList());

setFacets(facets.value);
setBaseConditions(buildApiConditions(baseConditions.value));

const { data: SeminarListData } = (await getSeminarList(node.value, langcode.value)) as {
  data: Ref<OpenSearchNode>;
};
contextStore.setContent(SeminarListData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(SeminarListData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
    "title",
    backendMetatags?.title ?? SeminarListData.value?.entityById?.fieldHeader?.fieldTitle
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? SeminarListData.value?.entityById?.fieldHeader?.fieldIntroduction2,
    backendMetatags?.title ?? SeminarListData.value?.entityById?.fieldHeader?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

watch(
  () => route.query,
  async (query) => {
    reset();
    handleQueryBaseParameters(query);

    const facetConditions = buildApiConditionsFromQuery(query);
    setFacetConditions(facetConditions);
    setSortOrder({field: "field_date_begin", order: "DESC"});
    await fetchSearchData("seminarList");
  },
  { immediate: true, deep: true }
);

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
