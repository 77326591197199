<template>
  <div class="container mx-auto">
    <!-- Back Link -->
    <UiCustomLink
      v-if="backLink"
      :path="backLink"
      customClass="font-bold flex items-center font-raleway text-regularMobile lg:text-regular mt-14 lg:mt-20 mb-[22px] lg:mb-10 hover:text-orangeCurie"
    >
      <nuxt-icon name="chevronLeft" filled class="mt-1 mr-4 text-xl" />
      {{ $t('publication.list') }}
    </UiCustomLink>
    <!-- Back Link end -->
  </div>

  <div class="pt-2.5 pb-10 border-b border-grisClair lg:pb-16 lg:pt-6">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-12">
        <div class="col-span-1 lg:col-span-7">
          <h1
            v-if="Publication.entityById.fieldTitle"
            class="text-h3 lg:text-h1 font-lora leading-[45px] lg:leading-[57px] mb-10 lg:mb-0"
          >
            {{ Publication.entityById.fieldTitle }}
          </h1>
        </div>
        <div class="col-span-1 self-end lg:col-start-9 lg:col-span-4">
          <div class="px-4 py-6 border border-grisClair lg:p-6 rounded">
            <div class="flex flex-col">
              <span
                v-if="Publication.entityById.fieldDate"
                class="pb-1 text-orangeCurie font-raleway text-small font-bold"
                >{{ formattedDate(Publication.entityById.fieldDate.value) }}</span
              >
              <span v-if="Publication.entityById?.fieldPublicationNewspaper?.name" class="pb-6 font-raleway text-regular">
                {{ Publication.entityById?.fieldPublicationNewspaper?.name }}</span
              >
              <p v-if="Publication.entityById.fieldDigitalObjectIdentifier" class="font-raleway text-regular">
                DOI :
                <UiCustomLink
                  :path="`https://doi.org/${Publication.entityById.fieldDigitalObjectIdentifier}`"
                  class="font-bold underline"
                  >{{ Publication.entityById.fieldDigitalObjectIdentifier }}</UiCustomLink
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mx-auto">
    <div class="grid grid-cols-12 mt-14 lg:mt-20">
      <div class="col-span-12 lg:col-start-3 lg:col-span-8 lg:pl-[110px] lg:border-l lg:border-grisClair">
        <div v-if="Publication.entityById.fieldAuthor" class="mb-14 lg:mb-20">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("publication.authors") }}
          </h2>
          <p class="font-raleway text-regularMobile lg:text-regular leading-[25px]">
            {{ Publication.entityById.fieldAuthor }}
          </p>
        </div>
        <div v-if="Publication.entityById.fieldDescription" class="pb-14 lg:pb-20">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("publication.abstract") }}
          </h2>
          <div
            v-html="Publication.entityById.fieldDescription"
            class="font-raleway text-regularMobile lg:text-regular leading-[25px]"
          />
        </div>
        <!-- Team -->
        <div class="mb-14 lg:mb-20" v-if="Publication.entityById.computedPublicationTeam.length > 0">
          <h2 class="m-0 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("publication.teams") }}
          </h2>
          <div
            v-for="team in Publication.entityById.computedPublicationTeam"
            :key="index"
            class="flex flex-col gap-4 justify-end p-4 mb-4 rounded border lg:flex-row lg:justify-between lg:gap-6 lg:p-6 border-grisClair group"
          >
            <div class="flex flex-col gap-2.5 lg:basis-[62%]">
              <span
                class="w-fit rounded text-orangeCurie border border-orangeCurie font-raleway text-[15px] px-1 py-0.5"
              >
                {{ team.fieldTeamType[0].name }}</span
              >
              <UiCustomLink :path="team.pathRawField.list[0].alias"
                ><h3
                  class="m-0 font font-raleway font-bold text-regular leading-[26px] lg:text-medium hover:text-orangeCurie"
                >
                  {{ team.fieldTitle }}
                </h3>
              </UiCustomLink>
              <p
                v-for="chief in getChiefs(team.fieldTeamMember)"
                class="font-lora text-medium lg:text-h3Small leading-[24px]"
              >
                {{ chief.fieldTitle || `${chief.fieldPerson.fieldFirstName} ${chief.fieldPerson.fieldLastName}` }}
              </p>
            </div>
            <div class="flex flex-row gap-2 items-start overflow-hidden w-[94px] h-[94px] rounded">
              <UiCustomImage
                v-if="getChiefs(team.fieldTeamMember)"
                v-for="chief in getChiefs(team.fieldTeamMember)"
                :src="chief.fieldPerson.fieldImage.entity.uriRawField.list[0].url"
                :alt="chief.fieldPerson.fieldImage.alt"
                presets="team_members"
                class="lg:w-full group-hover:scale-[1.1] transition ease delay-600 object-cover max-h-[100%]"
              />
              <NuxtImg
                  v-else
                  class="lg:w-full group-hover:scale-[1.1] transition ease delay-600 object-cover"
                  presets="team_members"
                  src="/image/default.png"
                  alt="Default"
              />
            </div>
          </div>
        </div>

        <!-- Members -->
        <div v-if="personArray.length > 0" class="flex flex-col mb-14 lg:mb-20">
          <h2 class="m-0 col-span-12 font-lora font-normal leading-[33px] text-h3Medium lg:text-h2Small pb-6 lg:pb-10">
            {{ $t("publication.members") }}
          </h2>
          <div class="grid grid-cols-12 gap-4 lg:gap-6">
            <div
              v-for="member in personArray"
              :key="index"
              class="flex flex-col col-span-6 justify-between p-4 rounded border border-grisClair lg:col-span-4"
            >
              <div>
                <UiCustomLink :path="member.pathRawField.list[0].alias">
                  <UiCustomImage
                    v-if="member.fieldImage"
                    :src="member.fieldImage.entity.uriRawField.list[0]?.url"
                    :alt="member.fieldImage.alt"
                    presets="team_members"
                    class="object-cover w-full h-auto rounded"
                  />
                  <NuxtImg
                      v-else
                      class="object-cover w-full h-auto rounded"
                      presets="team_members"
                      src="/image/default.png"
                      alt="Default"
                  />
                  <h3 class="font-lora m-0 mt-[15px] font-normal leading-[26px] text-medium lg:text-h3Small">
                    {{ member.fieldFirstName }} {{ member.fieldLastName }}
                  </h3>
                </UiCustomLink>
                <span
                  v-for="(item, index) in member.fieldPersonTitle"
                  :key="index"
                  class="font-raleway text-small font-normal leading-[140%]"
                >
                  {{ item.name }}
                </span>
              </div>
              <div class="flex items-center mt-4">
                <UiCustomLink :path="`${member.pathRawField.list[0].alias}#contact`" class="mr-2">
                  <nuxt-icon name="mail" filled class="cursor-pointer text-h3Small" />
                </UiCustomLink>
                <UiCustomLink
                  v-if="member?.fieldLink?.length > 0"
                  v-for="icon in member?.fieldLink"
                  :key="icon?.title"
                  :path="icon.uri.path"
                  class="mr-2"
                >
                  <NuxtIcon :name="icon.title" />
                </UiCustomLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import { useBackLink } from "~/composables/backlink";
import getPublication from "~/composables/service/graphql/client/publication/index";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import type { SeoTags } from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";
import { getDefaultPageTags } from "~/utils/cacheTags";

import {filterArray} from '~/utils/filterDataUtils';

const contextStore = useContextStore();

const route = useRoute();
const config = useRuntimeConfig();
const locale = config.public.locale;
const langcode = config.public.langcode;
const { data: Publication } = await getPublication(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(Publication.entityById);
const { getBackLink } = useBackLink();
const backLink = getBackLink();

const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(Publication.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? Publication.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? Publication?.entityById?.fieldDescription,
    backendMetatags?.title ?? Publication?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

function formattedDate(dateString: string) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    localeMatcher: "best fit",
  };
  return date.toLocaleDateString(locale, options).replace(/\s/g, " ");
}

function getChiefs(teamMembers) {
  return teamMembers?.filter((member) => member.fieldChief === "1" && member.fieldPerson != null);
}
const teamsTag = [];
if(Publication.entityById?.computedPublicationTeam){
   teamsTag.push(...Publication.entityById?.computedPublicationTeam.map((news)=>{return `page:${news.id}`}));
}

const personArray = filterArray(Publication.entityById?.fieldAuthorCurie);

const authorsTag = [];
if(personArray.length > 0){
  authorsTag.push(...personArray.map((news)=>{return `page:${news.id}`}));
}

const tags = [`page:${Publication.entityById.id}`, ...getDefaultPageTags(), ...teamsTag, ...authorsTag];
useCDNHeaders((helper) => {
  helper
    .addTags(tags)
    .public()
    .setNumeric('maxAge', 86400)
    .setNumeric('staleIfError', 43200)
})
</script>
