import { TeamQuery } from "../../query/content/Team";
import type { TeamItems } from "~/interfaces/team";
import { getTeamCacheTag } from "~/utils/cacheTags";

export default async function getTeam(id: string, langcode: string) {
  const key = `page--${id}`;
  const nuxtApp = useNuxtApp();
  const { client } = useApolloClient();
  const { data: team } = await useAsyncData(key, async () => {
    try {
      const { value, addToCache } = await useDataCache(key)
      if (value) {
        return value
      }
      const { data: response } = await client.query<TeamItems>({query: TeamQuery, variables:{ id, langcode }});
      const TAGS : string[] = getTeamCacheTag(response);
      await addToCache(response, TAGS);
      return response;
    } catch (err) {  
      console.error(err);  
    }
  })
  return {data: team.value };
}
