<template>
  <div>
    <div v-if="TeamListData?.entityById">
      <CommonFieldHeader
        v-if="TeamListData.entityById.fieldHeader"
        :fieldHeaderData="TeamListData.entityById.fieldHeader"
        :isListPage="true"
        class="mt-12"
        :showIntroduction="pageNumber<2"
        :customTitle="`${TeamListData.entityById.fieldHeader.fieldTitle}${pageNumber >1 ? ` - page ${pageNumber}`:''}`"
      />
      <div class="container mx-auto">
        <div id="list" class="grid grid-cols-12 mb-14 mt-10 lg:mt-20 lg:mb-20">
          <div class="col-span-12 block lg:hidden">
            <div class="pb-6 font-lora text-h3Medium leading-[33px] lg:text-h2Small lg:pb-10 font-normal m-0">
              <span v-if="TeamListData.entityById.fieldTeamType.length > 1">  <!-- 2 elements is a teamListPage-->
                {{ $t("teamList.searchTitle") }}
              </span>
              <span v-else>                                                   <!-- 1 elements is a platformListPage-->
                {{ $t("platformList.searchTitle") }}
              </span>
            </div>
            <ListSearchForm list="team" />
          </div>
          <div class="col-span-12 mb-6 lg:col-span-4 lg:mb-0 lg:pb-0 lg:pr-20">
            <ListFacets />
          </div>
          <div class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l lg:border-grisClair">
            <h2 class="hidden lg:block pb-6 font-lora text-h3Medium lg:text-h2Small lg:pb-10 font-normal m-0">
              <span v-if="TeamListData.entityById.fieldTeamType.length > 1">  <!-- 2 elements is a teamListPage-->
                {{ $t("teamList.searchTitle") }}
              </span>
              <span v-else>                                                   <!-- 1 elements is a platformListPage-->
                {{ $t("platformList.searchTitle") }}
              </span>
            </h2>
            <ListSearchForm list="team" class="hidden lg:flex" />
            <ListItems v-if="searchData.count > 0" list="team" />
            <div v-else>
              <p class="font-raleway text-regularMobile lg:text-regular">{{ $t("teamList.noResults") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import useConditionsBuilder from "~/composables/conditions/builder";
import useQuery from "~/composables/query";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import getTeamList from "~/composables/service/graphql/client/team-list";

import type { Condition, Facet, OpenSearchNode, OpenSearchFieldTeamType } from "~/interfaces/list";
import type { SeoTags } from "~/interfaces/seo";


import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();


const config = useRuntimeConfig();
const route = useRoute();
const { t } = useI18n();
const pageNumber = computed(()=> route.query.page ?? 1);
const node = computed(() => routeStore.route?.name?.toString()!);
const baseConditions = ref<Condition[]>([
  { field: "status", values: "1", conjunction: "or" },
  { field: "type", values: "team", conjunction: "or" },
]);
const facets = ref<Facet[]>([
  { label: t("teamList.fieldScientificAxis"), type: "Checkbox", field: "field_scientific_axis" },
  { label: t("teamList.computedTeamRelatedUnit"), type: "Checkbox", field: "computed_team_related_unit" },
  { label: t("teamList.computedTeamChiefPerson"), type: "Checkbox", field: "computed_team_chief_person" },
  { label: t("teamList.fieldSites"), type: "Checkbox", field: "field_sites" },
]);

let TeamListData = ref<OpenSearchNode | null>(null);

const { setBaseConditions, setFacetConditions, setFacets, fetchSearchData, setSortOrder } = useList();
const { buildApiConditions, buildApiConditionsFromQuery } = useConditionsBuilder();
const { handleQueryBaseParameters,reset } = useQuery();
//reset();
const { langcode, searchData } = storeToRefs(useList());

try {
  const response = await getTeamList(node.value, langcode.value);
  if (response?.data?.value) {
    TeamListData.value = response.data.value as OpenSearchNode;

    contextStore.setContent(TeamListData.value?.entityById);

    const teamType: string[] = [];
    TeamListData.value.entityById.fieldTeamType?.forEach((type: OpenSearchFieldTeamType) => {
      teamType.push(type.id);
    });
    baseConditions.value.push({
      field: "field_team_type",
      values: teamType,
      conjunction: "or",
    });
    setBaseConditions(buildApiConditions(baseConditions.value));
  } else {
    throw new Error("No data received from getTeamList");
  }
} catch (error) {
  console.error("Error fetching team list:", error);
}

setFacets(facets.value);

const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const backendMetatags = getBackendMetatags(TeamListData.value?.entityById?.fieldMetatag);

const { currentPage } = storeToRefs(useList());

const getCanonicalUrl = (page) => {
  const queries = {...route.query};
  if(page>1){
  queries.page = page;
  }else{
    delete queries.page;
  }
  const queryParamsStr = Object.keys(queries).map(key => `${key}=${queries[key]}`).join("&");
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.path}${queryParamsStr ? '?'+queryParamsStr :""}`;
  return canonicalUrl;
}

const getFormattedMetatags = <SeoTags>(() => {
  const backendMetatags = getBackendMetatags(TeamListData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
    "title",
    (backendMetatags?.title ?? TeamListData.value?.entityById?.fieldHeader?.fieldTitle)
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? TeamListData.value?.entityById?.fieldHeader?.fieldIntroduction2
  );
 
  const canonicalUrl = getCanonicalUrl(route.query.page?? 1);

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

const formattedMetatags = getFormattedMetatags();

const getPaginateMeta = (page) => {
  let suffix = '';
  if(page>1){
    suffix = ` - page ${page}`;
  }
  return {title: `${formattedMetatags.title}${suffix}`, canonical_url: getCanonicalUrl(page) , description: `${formattedMetatags.description}${suffix}`};
}

watch(
  ()=> currentPage.value,
  (value)=> { 
    setMetaTags(getPaginateMeta(value))
  },
  { immediate: true, deep: true }
)

watch(
  () => route.query,
  async (query) => {
    reset();
    handleQueryBaseParameters(query);

    const facetConditions = buildApiConditionsFromQuery(query);
    setFacetConditions(facetConditions);
    setSortOrder({field: "field_title", order: "ASC"});
    await fetchSearchData("teamList");
  },
  { immediate: true, deep: true }
);

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
