<template>
  <div v-if="editoFlexibleData" class="mt-10">
    <CommonFieldHeader :fieldHeaderData="editoFlexibleData.entityById.fieldHeader" :isListPage="true" class="mt-12" />
    <CommonFieldAnchor :fieldAnchorData="editoFlexibleData.entityById.fieldAnchor" />
    <section
      v-for="(section, index) in editoFlexibleData.entityById.fieldSection"
      :key="index"
      :class="getSectionClass(section.fieldTypeSection)"
      class="mt-10 mb-20 lg:mt-20"
    >
      <component
        v-for="paragraph in section.fieldParagraphs"
        :key="paragraph.id"
        :is="getComponentByType(paragraph.__typename)"
        :paragraphData="paragraph"
        :id="paragraph.fieldAnchor"
        :sectionType="section.fieldTypeSection"
        class="my-10"
      />
    </section>
    <div v-if="editoFlexibleData.entityById.fieldFooter" class="container mx-auto">
      <component
        v-for="paragraph in editoFlexibleData.entityById.fieldFooter"
        :key="paragraph.fieldParagraph.id"
        :is="getComponentByType(paragraph.fieldParagraph.__typename)"
        :paragraphData="paragraph.fieldParagraph"
        class="my-10"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getEditoFlexible from "~/composables/service/graphql/client/edito-flexible";
import { getComponentByType } from "~/utils/componentMapper";

import type { SeoTags } from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
const contextStore = useContextStore();


const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { data: editoFlexibleData } = await getEditoFlexible(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(editoFlexibleData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(editoFlexibleData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
    "title",
    backendMetatags?.title ?? editoFlexibleData.value?.entityById?.fieldHeader?.fieldTitle
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? editoFlexibleData.value?.entityById?.fieldHeader?.fieldIntroduction2,
    backendMetatags?.title ?? editoFlexibleData.value?.entityById?.fieldHeader?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

// Fonction pour obtenir la classe de section basée sur le type
function getSectionClass(sectionType: string): string {
  if (sectionType === "border") {
    return "lg:max-w-[870px] mx-auto lg:border-l lg:border-grisClair xl:pl-28 px-4";
  } else if (sectionType === "normal") {
    return "lg:max-w-[870px] mx-auto px-4";
  } else if (sectionType === "full") {
    return "lg:container mx-auto px-4";
  }
  return "";
}


useCDNHeaders((helper) => {
  helper
    .private();
})

useJsonld(() => ({
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  headline: editoFlexibleData.value?.entityById?.fieldHeader?.fieldTitle,
  image: [
    editoFlexibleData.value?.entityById?.fieldHeader?.fieldImage?.entity.uriRawField.list[0].url
  ]
}));
</script>
