<template>
  <div class="mb-14 lg:mb-20">
    <TeamHeader
      :fieldTeamMember="fieldUnitMember"
      :fieldTitle="unitData.entityById.fieldTitle"
      :fieldSurtitle="unitData.entityById.fieldUnitType.name"
      :fieldUnit="unitData.entityById.computedTeamRelatedUnit"
    />
    <TeamPartners :fieldPartners="unitData.entityById.fieldPartners" :fieldSites="unitData.entityById.fieldSites" />
    <section id="content" class="container grid grid-cols-1 mx-auto lg:grid-cols-12 pt-[34px] lg:pt-16 scroll-mt-24">
      <div class="lg:border-r lg:border-grisClair lg:col-span-4">
        <TeamMenu
          v-if="menuItems.length > 0"
          :menuItems="menuItems"
          :activeSection="activeSection"
          @set-section="setActiveSection"
          class="hidden lg:block"
        />
        <TeamMobileMenu
          v-if="menuItems.length > 0"
          :menuItems="menuItems"
          :activeSection="activeSection"
          @set-section="setActiveSection"
          class="block lg:hidden"
        />
      </div>
      <div class="lg:col-span-8 lg:col-start-6 lg:grid-cols-subgrid">
        <!-- * Présentation -->
        <TeamPresentation
          v-show="activeSection === '#presentation'"
          :fieldImage="unitData.entityById.fieldImage"
          :fieldIntroduction="unitData.entityById.fieldIntroduction"
          :fieldPresentation="unitData.entityById.fieldPresentation"
        />
        <!-- * Composant fieldScientificAxis -->
        <TeamScientificAxis
          v-show="activeSection === '#scientificaxis'"
          :fieldScientificAxis="unitData.entityById.fieldScientificAxis"
          :fieldTextScientificAxis="unitData.entityById.fieldTextScientificAxis"
        />
        <!-- * Composant TeamUnitTeam -->
        <TeamUnitTeam v-show="activeSection === '#members'" :fieldTeam="unitData.entityById.fieldTeam" />
        <!-- * Composant fieldPublications -->
        <TeamPublication
          v-show="activeSection === '#publications'"
          :title="$t('team.publications')"
          :fieldPublications="unitData.entityById.fieldPublications"
          :unitId="unitData.entityById.id"
        />
        <!-- * Collection Paragraph fieldLifeTeam -->
        <TeamLifeTeam v-show="activeSection === '#lifeteam'" :fieldLifeTeam="unitData.entityById.fieldLifeTeam" />
        <!-- * Composant fieldIntroductionJoinUs -->
        <TeamJoinUs
          v-show="activeSection === '#joinus'"
          :fieldIntroductionJoinUs="unitData.entityById.fieldIntroductionJoinUs"
          :fieldRelatedJobs="unitData.entityById.computedTeamUnitRelatedJob"
        />
        <TeamSeminars v-show="activeSection === '#seminars'" :fieldSeminarsTeam="unitData.entityById?.fieldSeminars" />
        <!-- * Composant TeamNews -->
        <TeamNews v-show="activeSection === '#news'" :fieldNewsTeam="unitData.entityById?.computedNewsTeam" />
        <TeamBottomNavigationDesktop
          :menuItems="menuItems"
          :activeSection="activeSection"
          @set-section="setActiveSection"
          class="hidden lg:block"
        />
        <TeamBottomNavigationMobile
          :menuItems="menuItems"
          :activeSection="activeSection"
          @set-section="setActiveSection"
          class="block w-full lg:hidden"
        />
      </div>
    </section>
    <section class="container mx-auto my-14 lg:my-20">
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        class="w-fit flex mx-auto justify-center items-center text-lg font-bold underline hover:text-orangeCurie"
      >
        {{ $t("unit.seeAll") }}
        <NuxtIcon name="arrowRight" filled class="ml-1" />
      </UiCustomLink>
      <component
        v-for="paragraph in unitData.entityById.fieldFooter"
        :key="paragraph.fieldParagraph.id"
        :is="getComponentByType(paragraph.fieldParagraph.__typename)"
        :paragraphData="paragraph.fieldParagraph"
        class="my-14"
      />
    </section>
  </div>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getUnit from "~/composables/service/graphql/client/unit";
import { useBackLink } from "~/composables/backlink";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import type { MenuItem } from "~/interfaces/team";
import type { SeoTags } from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";

import { filterTeamMember } from '~/utils/filterDataUtils';

const contextStore = useContextStore();

const { t } = useI18n();
const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { data: unitData } = await getUnit(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(unitData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(unitData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? unitData.value?.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? unitData.value?.entityById?.fieldPresentation[0]?.fieldBody,
    backendMetatags?.title ?? unitData.value?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);
const { getBackLink } = useBackLink();
const backLink = getBackLink();

const activeSection = ref(route.hash ? route.hash : "#presentation");

const setActiveSection = (section: string) => {
  activeSection.value = section;
  scrollToContent();
};

watch(
  () => route.hash, 
  () => {
  activeSection.value=route.hash;
})
import { nextTick } from "vue";
watch(
() => activeSection.value,
async () => {
  await nextTick();
  scrollToContent();
})

const scrollToContent = () => {
  let anchor = document.querySelector("#content");
  anchor?.scrollIntoView();
};

const menuConfig = ref<MenuItem[]>([
  { key: "fieldPresentation", name: t("unit.presentation"), link: "#presentation" },
  {
    key: "fieldScientificAxis",
    name: t("unit.fieldScientificAxis"),
    link: "#scientificaxis",
  },
  { key: "fieldTeamMember", name: t("unit.members"), link: "#members" },
  {
    key: "fieldPublications",
    name: t("unit.publications"),
    link: "#publications",
  },
  { key: "computedNewsTeam", name: t("unit.actualities"), link: "#news" },
  {
    key: "fieldSeminars",
    name: t("team.seminars"),
    link: "#seminars",
  },
  { key: "fieldLifeTeam", name: t("unit.lifeTeam"), link: "#lifeteam" },
  {
    key: "fieldIntroductionJoinUs",
    name: t("unit.joinUs"),
    link: "#joinus",
  }
]);

const fieldUnitMember = filterTeamMember(unitData.value?.entityById.fieldTeamMember);

const menuItems = computed<MenuItem[]>(() => {
  return menuConfig.value?.filter((section) => {
    const data = unitData.value?.entityById?.[section.key];
    return data && (Array.isArray(data) ? data.length > 0 : data.trim().length > 0);
  });
});

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
