import { gql } from "graphql-tag";

export const ListSeminarQuery = gql`
  query ListSeminarQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      ... on NodeSeminarList {
        id
        langcode
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldHeader {
          ...headerParagraph
        }
      }
    }
  }

  fragment headerParagraph on ParagraphHeader {
    id
    type: entityBundle
    fieldTitle
    fieldIntroduction2
    fieldImage {
      id: targetId
      alt
      entity {
        id
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldTitlePush
    fieldTextPush: fieldBody
    fieldLinkPush: fieldLink {
      title
      uri {
        path
      }
    }
  }
`;
