<template>
  <div>
    <div v-if="ClinicalTrialListData?.entityById">
      <CommonFieldHeader
        v-if="ClinicalTrialListData.entityById.fieldHeader"
        :fieldHeaderData="ClinicalTrialListData.entityById.fieldHeader"
        :isListPage="true"
        :showIntroduction="pageNumber<2"
        :customTitle="`${ClinicalTrialListData.entityById.fieldHeader.fieldTitle}${pageNumber >1 ? ` - page ${pageNumber}`:''}`"
        class="mt-12"
      />

      <div class="container mx-auto">
        <div id="list" class="grid grid-cols-12 mb-10 lg:mb-20 mt-10 lg:mt-20 lg:mb-[73px]">
          <div class="col-span-12 block lg:hidden">
            <div class="pb-6 font-lora text-h3Medium leading-[33px] lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("clinical.searchTitle") }}
            </div>
            <ListSearchForm list="clinical_trial" />
          </div>
          <div class="col-span-12 mb-6 lg:col-span-4 lg:mb-0 lg:pb-0 lg:pr-20">
            <ListFacets />
          </div>
          <div class="col-span-12 lg:col-start-5 lg:col-span-8 lg:pl-20 lg:border-l border-grisClair">
            <h2 class="hidden lg:block pb-6 font-lora text-h3Medium lg:text-h2Small lg:pb-10 font-normal m-0">
              {{ $t("clinical.searchTitle") }}
            </h2>
            <ListSearchForm list="clinical_trial" class="hidden lg:flex" />
            <ListItems v-if="searchData.count > 0" list="clinical_trial" />
            <div v-else>
              <p class="font-raleway text-regularMobile lg:text-regular">{{ $t("clinical.noResults") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import useConditionsBuilder from "~/composables/conditions/builder";
import useQuery from "~/composables/query";

import getClinicalTrialList from "~/composables/service/graphql/client/clinical-trial-list";

import type { Condition, Facet, OpenSearchNode } from "~/interfaces/list";
import type { SeoTags } from "~/interfaces/seo";

import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();

const config = useRuntimeConfig();
const route = useRoute();
const pageNumber = computed(()=> route.query.page ?? 1);
const { t } = useI18n();

const node = computed(() => routeStore.route?.name?.toString()!);
const baseConditions = ref<Condition[]>([
  { field: "status", values: "1", conjunction: "or" },
  { field: "type", values: "clinical_trial", conjunction: "or" },
]);
const facets = ref<Facet[]>([
  { label: t("clinical.fieldClinicalTrialLocation"), type: "Checkbox", field: "field_clinical_trial_location1" },
  { label: t("clinical.fieldClinicalTrialCancerType"), type: "Checkbox", field: "field_clinical_trial_cancer_type" },
  { label: t("clinical.fieldClinicalTrialOrgan"), type: "Checkbox", field: "field_clinical_trial_organ" },
  { label: t("clinical.fieldClinicalTrialTarget"), type: "Checkbox", field: "field_clinical_trial_target" },
  { label: t("clinical.fieldClinicalTrialPhase"), type: "Checkbox", field: "field_clinical_trial_phase" },
  { label: t("clinical.fieldClinicalTrialStatus"), type: "Checkbox", field: "field_clinical_trial_status" },
]);

const { setBaseConditions, setFacetConditions, setFacets, fetchSearchData } = useList();
const { buildApiConditions, buildApiConditionsFromQuery } = useConditionsBuilder();
const { handleQueryBaseParameters, reset } = useQuery();
const { langcode, searchData } = storeToRefs(useList());
//reset();
setFacets(facets.value);
setBaseConditions(buildApiConditions(baseConditions.value));

const { data: ClinicalTrialListData } = (await getClinicalTrialList(node.value, langcode.value)) as {
  data: Ref<OpenSearchNode>;
};
contextStore.setContent(ClinicalTrialListData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const { currentPage } = storeToRefs(useList());

const getCanonicalUrl = (page) => {
  const queries = {...route.query};
  if(page>1){
  queries.page = page;
  }else{
    delete queries.page;
  }
  const queryParamsStr = Object.keys(queries).map(key => `${key}=${queries[key]}`).join("&");
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.path}${queryParamsStr ? '?'+queryParamsStr :""}`;
  return canonicalUrl;
}

const backendMetatags = getBackendMetatags(ClinicalTrialListData.value?.entityById?.fieldMetatag);
const getFormattedMetatags = <SeoTags>(() => {
  const backendMetatags = getBackendMetatags(ClinicalTrialListData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag(
    "title",
    (backendMetatags?.title ?? ClinicalTrialListData.value?.entityById?.fieldHeader?.fieldTitle)
  );
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? ClinicalTrialListData.value?.entityById?.fieldHeader?.fieldIntroduction2
  );
 
  const canonicalUrl = getCanonicalUrl(route.query.page?? 1);

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});
const formattedMetatags = getFormattedMetatags();

const getPaginateMeta = (page) => {
  let suffix = '';
  if(page>1){
    suffix = ` - page ${page}`;
  }
  return {title: `${formattedMetatags.title}${suffix}`, canonical_url: getCanonicalUrl(page) , description: `${formattedMetatags.description}${suffix}`};
}

watch(
  ()=> currentPage.value,
  (value)=> { 
    setMetaTags(getPaginateMeta(value))
  },
  { immediate: true, deep: true }
)
setMetaTags(getPaginateMeta(route.query.page ?? 1))

watch(
  () => route.query,
  async (query) => {
        reset();
    handleQueryBaseParameters(query);

    const facetConditions = buildApiConditionsFromQuery(query);
    setFacetConditions(facetConditions);

    await fetchSearchData("clinicalTrialList");
  },
  { immediate: true, deep: true }
);


useCDNHeaders((helper) => {
  helper
    .private();
})
</script>
