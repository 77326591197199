<template>
  <!-- Header contact -->
  <CommonFieldHeader
    v-if="contactData.entityById.fieldHeader"
    :fieldHeaderData="contactData.entityById.fieldHeader"
    :isListPage="true"
    class="mt-12"
  />
  <!-- Header contact end -->
  <div class="container mx-auto">
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-12 pt-10 lg:pt-20">
      <div class="col-span-1 lg:col-span-5 h-fit">
        <div class="px-4 py-6 lg:p-10 bg-grisBackground rounded h-full">
          <h2 class="m-0 mb-6 font-lora font-normal text-h3Medium lg:text-h2Small leading-[33px] lg:leading-[40px]">
            {{ contactData?.entityById?.fieldTitleLeftBlock }}
          </h2>
          <p class="font-raleway text-regularMobile lg:text-regular leading-[25px] mb-4">
            {{ contactData?.entityById?.fieldTextLeftBlock }}
          </p>
          <UiButton
            :buttonLabel="contactData?.entityById?.fieldLinkLeftBlock.title"
            size="medium"
            :withIcon="false"
            class="w-full text-center lg:w-fit h-auto"
            :url="contactData?.entityById?.fieldLinkLeftBlock.uri.path"
          />
        </div>
      </div>
      <div class="col-span-1 lg:col-span-7">
        <!-- Formulaire contact start -->
        <ContactForm
          :title="contactData?.entityById?.fieldTitleRightBlock"
          :subjects="contactData?.entityById?.computedContactSubject"
        />
        <!-- Formulaire contact end -->
      </div>
    </div>
    <div class="mt-14 lg:mt-20 mb-14 lg:mb-20">
      <component
        v-for="paragraph in contactData.entityById.fieldParagraphs"
        :key="paragraph.id"
        :is="getComponentByType(paragraph.__typename)"
        :paragraphData="paragraph"
        class="my-10"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import getContact from "~/composables/service/graphql/client/contact/index";

import type { SeoTags } from "~/interfaces/seo";
import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();

import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();

const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;
const { data: contactData } = await getContact(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(contactData.value?.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(contactData.value?.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? contactData.value?.entityById?.fieldHeader?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? contactData.value?.entityById?.fieldHeader?.fieldIntroduction2,
    backendMetatags?.title ?? contactData.value?.entityById?.fieldHeader?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

useCDNHeaders((helper) => {
  helper
    .private();
})
</script>

<style>
.CustomStyle {
  h5 {
    @apply font-lora text-regular lg:text-h3Small m-0 mb-4;
  }
}

.CustomMapStyle {
  @apply flex flex-col lg:flex-row gap-4  lg:gap-16;
}

.CustomMapStyleItem {
  @apply basis-[100%] lg:basis-[50%];
}

.CustomMapStyleItemRight {
  h3 {
    @apply font-lora text-h3Medium lg:text-h2Small m-0 mb-4;
  }
}
</style>
