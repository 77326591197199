<template>
  <div class="mb-14 lg:mb-20">
    <TeamHeader
      :fieldTeamMember="fieldTeamMember"
      :fieldTitle="teamData.entityById.fieldTitle"
      :fieldSurtitle="teamData.entityById.fieldTeamType[0]?.name"
      :fieldUnit="teamData.entityById.computedTeamRelatedUnit"
    />
    <TeamPartners
      class="mt-[29px] mb-[34px] lg:mb-20 lg:mt-16"
      :fieldPartners="teamData.entityById.fieldPartners"
      :fieldSites="teamData.entityById.fieldSites"
    />
    <section id="content" class="container grid grid-cols-1 mx-auto lg:grid-cols-12 scroll-mt-24">
      <div class="lg:border-r lg:border-grisClair lg:col-span-4">
        <ClientOnly>
          <TeamMenu
            v-if="menuItems.length > 0"
            :menuItems="menuItems"
            :activeSection="activeSection"
            :activeSubMenu="activeSubMenu"
            @set-sub-menu="setActiveSubMenu"
            class="hidden lg:block"
          />
          <TeamMobileMenu
            v-if="menuItems.length > 0"
            :menuItems="menuItems"
            :activeSection="activeSection"
            :activeSubMenu="activeSubMenu"
            @set-sub-menu="setActiveSubMenu"
            class="block lg:hidden"
          />
        </ClientOnly>
      </div>
      <div class="lg:col-span-8 lg:col-start-6 lg:grid-cols-subgrid">
        <!-- * Présentation -->
        <TeamPresentation
          v-show="isActiveSection('#presentation')"
          :fieldImage="teamData.entityById.fieldImage"
          :fieldIntroduction="teamData.entityById.fieldIntroduction"
          :fieldPresentation="teamData.entityById.fieldPresentation"
        />
        <!-- * Composant fieldScientificAxis -->
        <TeamScientificAxis
          v-show="isActiveSection('#scientificaxis')"
          :fieldScientificAxis="teamData.entityById.fieldScientificAxis"
          :fieldTextScientificAxis="teamData.entityById.fieldTextScientificAxis"
        />
        <!-- * Composant TeamMember & TeamMemberOld -->
        <TeamMembers
          v-show="isActiveSection('#members')"
          :fieldTeamMember="fieldTeamMember"
          :fieldTeamMemberOld="teamData.entityById.fieldTeamMemberOld"
        />
        <!-- * Composant fieldPublications -->
        <TeamPublication
          v-show="isActiveSection('#publications')"
          :title="$t('team.publications')"
          :fieldPublications="teamData.entityById.fieldPublications"
          :teamId="teamData.entityById.id"
        />
        <!-- * Collection Paragraph fieldLifeTeam -->
        <TeamLifeTeam v-show="isActiveSection('#lifeteam')" :fieldLifeTeam="teamData.entityById.fieldLifeTeam" />
        <!-- * Composant fieldIntroductionJoinUs -->
        <TeamJoinUs
          v-show="isActiveSection('#joinus')"
          :fieldIntroductionJoinUs="teamData.entityById.fieldIntroductionJoinUs"
          :fieldRelatedJobs="teamData.entityById.computedTeamUnitRelatedJob"
        />
        <!-- * Collection Paragraph TeamTab -->
        <TeamContent
          v-for="(content, index) in contents"
          :key="index"
          :content="content"
          :activeSection="activeSection"
          :activeSubMenu="activeSubMenu"
        />
        <!-- * Composant TeamNews -->
        <TeamNews v-show="isActiveSection('#news')" :fieldNewsTeam="teamData.entityById?.computedNewsTeam" />
        <TeamSeminars v-show="isActiveSection('#seminars')" :fieldSeminarsTeam="teamData.entityById?.fieldSeminars" />
        <ClientOnly>
          <TeamBottomNavigationDesktop
            :menuItems="flattenedMenuItems"
            :activeSection="activeSection"
            :activeSubMenu="activeSubMenu"
            @set-section="setActiveSection"
            @set-sub-menu="setActiveSubMenu"
            class="hidden lg:block"
          />
          <TeamBottomNavigationMobile
            :menuItems="menuItems"
            :activeSection="activeSection"
            :activeSubMenu="activeSubMenu"
            @set-section="setActiveSection"
            @set-sub-menu="setActiveSubMenu"
            class="block w-full lg:hidden"
          />
        </ClientOnly>
      </div>
    </section>
    <TeamOtherPartners class="my-14 lg:my-20" :fieldOtherPartners="teamData.entityById.fieldOtherPartners" />
    <section class="container mx-auto my-14 lg:my-20">
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        class="w-fit flex mx-auto justify-center text-center items-center text-lg font-bold underline hover:text-orangeCurie"
      >
        {{ $t("team.seeAll") }}
        <NuxtIcon name="arrowRight" filled class="ml-1" />
      </UiCustomLink>
    </section>
    <section class="container mx-auto">
      <component
        v-for="paragraph in teamData.entityById.fieldFooter"
        :key="paragraph.fieldParagraph.id"
        :is="getComponentByType(paragraph.fieldParagraph.__typename)"
        :paragraphData="paragraph.fieldParagraph"
      />
    </section>
  </div>
</template>
<script setup lang="ts">
import useSeo from "~/composables/seo";
import getTeam from "~/composables/service/graphql/client/team";
import { slugify } from "~/utils/stringUtils";
import { useBackLink } from "~/composables/backlink";
import { getTeamCacheTag, getDefaultPageTags } from "~/utils/cacheTags";
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
import type { FieldTeamTab, MenuItem, Content, SubItem } from "~/interfaces/team";
import type { SeoTags } from "~/interfaces/seo";
import { ref } from 'vue'
import {useContextStore} from "~/stores/context";

import { filterTeamMember } from '~/utils/filterDataUtils';

const contextStore = useContextStore();


const { t } = useI18n();
const route = useRoute();
const config = useRuntimeConfig();
const langcode = config.public.langcode;


const isActiveSection = (hash: string) => {
    return  activeSection.value == hash;
}

const { data: teamData } = await getTeam(routeStore.route?.name?.toString()!, langcode);
contextStore.setContent(teamData.entityById);
const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(teamData.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? teamData.entityById?.fieldTitle);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? teamData?.entityById?.fieldPresentation[0]?.fieldBody,
    backendMetatags?.title ?? teamData?.entityById?.fieldTitle
  );
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags };
});

setMetaTags(formattedMetatags.value);

const teamType = computed(() => {
  return teamData.entityById?.fieldTeamType[0]?.name;
});

const { getBackLink } = useBackLink();
const backLink = getBackLink("team_list", teamType.value);


const activeSubMenu = ref("");

const setActiveSubMenu = (subMenu?: string) => {
  if(activeSection.value){
      if(subMenu){
        const parentSection = menuItems.value.find((item) => item.subMenu?.some((subItem) => subItem.id === subMenu));
        activeSection.value = parentSection?.link || "";
        activeSubMenu.value = subMenu;
      }else if(activeSection.value == route.hash){
        const currentMenu = menuItems.value.find((item) => item.link == activeSection.value);
        if(currentMenu && currentMenu.subMenu && currentMenu.subMenu.length >0){
          activeSubMenu.value = currentMenu.subMenu[0].id;
        }else{
          activeSubMenu.value="";
        }
      }
    }
};


const scrollToContent = () => {
  let anchor = document.querySelector("#content");
  anchor?.scrollIntoView();
};

const activeSection = ref(route.hash ? route.hash : "#presentation");

watch(
  () => route.hash, 
  () => {
  activeSection.value=route.hash;
  setActiveSubMenu();
})
import { nextTick } from "vue";
watch(
() => activeSubMenu.value,
async () => {
  await nextTick();
  scrollToContent();
})


const contents = computed<Content[] | []>(() => {
  const entityById = teamData.entityById;
  if (!entityById) return [];

  return [1, 2, 3]
    .filter((i) => entityById[`fieldTitleContent${i}`] && entityById[`fieldTeamTab${i}`]?.length)
    .map((i) => {
      const title = entityById[`fieldTitleContent${i}`];
      const introduction = entityById[`fieldIntroductionContent${i}`];
      const tabs = entityById[`fieldTeamTab${i}`].map((tab: FieldTeamTab) => ({
        ...tab,
        id: `#tab-${slugify(tab.fieldTitle)}`,
      }));

      return {
        id: `#tab-${slugify(title)}`,
        title,
        introduction,
        tabs,
      };
    });
});

const tabMenuItems = computed<MenuItem[] | []>(() => {
  return (
    contents.value?.map((content, index) => {
      const subMenu = content.tabs.map((tab: FieldTeamTab) => ({
        id: `#tab-${slugify(tab.fieldTitle)}`,
        name: tab.fieldTitle,
      }));

      return {
        key: `fieldTeamTab${index + 1}`,
        name: content.title,
        link: content.id,
        subMenu,
      };
    }) || []
  );
});

const menuConfig: Ref<MenuItem[]> = ref([
  { key: "fieldPresentation", name: t("team.presentation"), link: "#presentation" },
  {
    key: "fieldScientificAxis",
    name: t("team.scientificAxis"),
    link: "#scientificaxis",
  },
  { key: "fieldTeamMember", name: t("team.members"), link: "#members" },
  ...tabMenuItems.value,
  {
    key: "fieldPublications",
    name: t("team.publications"),
    link: "#publications",
  },
  { key: "fieldLifeTeam", name: t("team.lifeTeam"), link: "#lifeteam" },
  { key: "computedNewsTeam", name: t("team.actualities"), link: "#news" },
  {
    key: "fieldIntroductionJoinUs",
    name: t("team.joinUs"),
    link: "#joinus",
  },
  {
    key: "fieldSeminars",
    name: t("team.seminars"),
    link: "#seminars",
  },
]);

const menuItems = computed<MenuItem[]>(() => {
  return menuConfig.value.filter((section) => {
    const data = teamData.entityById?.[section.key];
    return data && (Array.isArray(data) ? data.length > 0 : data.trim().length > 0);
  });
});

const flattenedMenuItems = computed<(MenuItem | SubItem)[]>(() => {
  return (
    menuItems.value?.flatMap((item) => {
      if (item.subMenu && item.subMenu.length > 0) {
        return item.subMenu;
      }
      return [item] as (MenuItem | SubItem)[];
    }) || []
  );
});

const fieldTeamMember = filterTeamMember(teamData.entityById.fieldTeamMember);

setActiveSubMenu();
const tags = [...getDefaultPageTags(),...getTeamCacheTag(teamData)];
useCDNHeaders((helper) => {
  helper
    .addTags(tags)
    .public()
    .setNumeric('maxAge', 86400)
    .setNumeric('staleIfError', 43200)
})
</script>
